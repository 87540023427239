import React from "react";
import {FlexRowContainer} from "components/FlexRowContainer";
import {SectionTitle} from "components/SectionTitle";
import Section from "components/Section";
import styled from 'styled-components';
import {Button} from "pages/MainPage/components/GetButton";
import {Container} from "components/Container";
import {AppTitle} from "components/AppTitle";
import {AppSubtitle} from "components/AppSubtitle";
import {ButtonsContainer} from "components/ButtonsContainer";
import BodyText from "components/BodyText";
import {useNavigate} from "react-router-dom";
import { layout, padding, margin } from 'styled-system'
import SubImg from 'assets/images/subscription-1.jpeg'


export const GrayBlock = styled.div`
    position: relative;
    background: #EEEEEF;
    border-radius: 4px;
    min-width: 106px;
    height: 106px;
    ${layout}
    ${padding}
    ${margin}
    
`;


export const Plus = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    --b: 4px; /* the thickness */
    width: 10.5px;
    height: 10.5px;
    aspect-ratio: 1;
    display: inline-block;
    ${layout}
  
    border: none;
    margin: 6px 5.5px;
    background:
    conic-gradient(from 90deg at var(--b) var(--b),transparent 90deg,#CECED0 0) 
    calc(100% + var(--b)/2) calc(100% + var(--b)/2)/
    calc(50%  + var(--b))   calc(50%  + var(--b));

`;

const Subscriptions = () => {
    const navigate = useNavigate();
    const handleSubscriptionOffer = () => navigate('/subscription-offer');

    return (
        <Section paddingBottom="29px">
            <FlexRowContainer justifyContent="space-between" marginBottom="9.5px">
                <SectionTitle>Subscriptions</SectionTitle>
            </FlexRowContainer>

            <FlexRowContainer>
                <GrayBlock minWidth='84px' height='84px' padding="11px">
                    <Plus />
                    <div style={{
                        height: '100%',
                        borderRadius: '5px',
                        borderTopRightRadius: '42px',
                        background: 'rgba(120, 120, 120, 0.6)',
                        backgroundImage: `url(${SubImg})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat'
                    }}>
                        {/*<img src={SubImg} alt="sub" height="100%" />*/}
                        &nbsp;
                    </div>
                </GrayBlock>

                <Container className="truncate">
                    <AppTitle className="truncate" fontFamily="SF Pro Text" letterSpacing="-0.22px" fontWeight="400">
                        Stories Editor for Instagram
                    </AppTitle>

                    <AppSubtitle className="truncate">
                        Premium access to all templates & fonts sdasd
                    </AppSubtitle>

                    <ButtonsContainer>
                        <div>
                            <Button
                                color="#3478f6"
                                background="#eeeeef"
                                padding="5px 9.5px 4.5px"
                                fontWeight="600"
                                fontSize="12px"
                                lineHeight="16px"
                                letterSpacing="0"
                                fontFamily="SF Pro Text Medium"
                                onClick={handleSubscriptionOffer}
                            >
                                FREE TRIAL
                            </Button>
                            <BodyText
                                marginTop="5px"
                                textAlign="center"
                                fontSize="8px"
                                lineHeight="9px"
                            >
                                See Details
                            </BodyText>
                        </div>
                    </ButtonsContainer>
                </Container>

            </FlexRowContainer>
        </Section>
    );
};


export default Subscriptions;