import styled from 'styled-components';
import {textAlign, margin, fontSize, lineHeight, layout, flexbox, fontWeight} from 'styled-system'
import GrayColorWrapper from "components/GrayColorWrapper";

const BodyText = styled(GrayColorWrapper('div'))`
    font-family: 'SF Pro Text';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.16px;
    ${textAlign}
    ${margin}
    ${fontSize}
    ${fontWeight}
    ${lineHeight}
    ${layout}
    ${flexbox}
`;


export default BodyText;