import React from "react";
import {Link} from "pages/MainPage/components/AppPrivacy";
import {useNavigate} from "react-router-dom";

const LearMore = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/learn-more-about-app-privacy')
    };

    return (
        <Link onClick={handleClick}>Learn More</Link>
    )
};

export default LearMore;