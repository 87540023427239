import styled from'styled-components';
import { margin, padding } from 'styled-system';
import GrayColorWrapper from "components/GrayColorWrapper";


export const SubTitle = styled(GrayColorWrapper('div'))`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    
    ${margin}
    ${padding}
`;