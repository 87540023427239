import {SectionTitle} from "components/SectionTitle";
import React from "react";
import {FlexRowContainer} from "components/FlexRowContainer";
import {SectionLink} from "components/SectionLink";
import Section from "components/Section";
import PrivacyCard from "components/PrivacyCard";
import {ReactComponent as DataUsedToTrackYouImg} from "assets/icons/data-used-to-track-you.svg";
import {ReactComponent as DataNotLinkedToYouImg} from "assets/icons/data-not-linked-to-you.svg";
import {SubTitle} from "components/SubTitle";
import LinkContainer from "components/LinkContainer";
import GrayColorWrapper from "components/GrayColorWrapper";
import {useNavigate} from "react-router-dom";
import LearnMore from "components/LearnMore";


export const Link = LinkContainer('span');
export const Body = GrayColorWrapper('div');


const AppPrivacy = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/app-privacy')
    };

    return (
        <Section paddingBottom="28px" >
            <FlexRowContainer justifyContent="space-between" marginBottom="9.5px">
                <SectionTitle>App Privacy</SectionTitle>
                <SectionLink onClick={handleClick}>See Details</SectionLink>
            </FlexRowContainer>

            <Body style={{position: 'relative', marginBottom: '-1.5px'}}>
                The developer, UAB KAMELA, indicated that the app's
                privacy practices may include handling of data as
                described below. For more information, see the&nbsp;
                <Link>developer's privacy policy.</Link>
            </Body>

            <PrivacyCard
                icon={<DataUsedToTrackYouImg />}
                title="Data Used to Track You"
                subtitle="The following data may be used to track you across apps and websites owned by other companies:"
                bodyItems={[
                    'purchases',
                    'identifiers'
                ]}
            />

            <PrivacyCard
                margin="27px 0 38px"
                icon={<DataNotLinkedToYouImg />}
                title="Data Not Linked to You"
                subtitle="The following data may be collected but it is not linked to your identity:"
                bodyItems={[
                    'purchases',
                    'userContent',
                    'identifiers',
                    'usageData',
                    'diagnostics'
                ]}
            />

            <SubTitle>
                Privacy practices may vary based, for example, on the
                features you use or your age. <LearnMore />
            </SubTitle>
        </Section>
    );
};


export default AppPrivacy;