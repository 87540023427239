import styled from "styled-components";
import { flexbox, margin } from 'styled-system'

export const Container = styled.div`
    margin-left: 13.5px;
    display: flex;
    flex-direction: column;
    flex: 1;
    ${flexbox}
    ${margin}
`;