import React from "react";
import LogoHubImg from "assets/icons/hub-icon.jpeg";
import ShareAndArrowUpImg from "assets/icons/share-and-arrow-up.svg";
import styled from 'styled-components';
import {FlexRowContainer} from "components/FlexRowContainer";
import {Button} from "pages/MainPage/components/GetButton";
import {Container} from "components/Container";
import {AppSubtitle} from "components/AppSubtitle";
import {ButtonsContainer} from "components/ButtonsContainer";
import {AppTitle} from "components/AppTitle";
import { padding, margin, textAlign} from 'styled-system'


export const InAppPurchases = styled.div`
font-family: SF Pro Text;
font-style: normal;
font-weight: 400;
font-size: 8px;
line-height: 9px;

display: flex;
align-items: center;
letter-spacing: -0.3px;

margin-left: 6.5px;
max-width: 37px;
${margin}
${padding}
${textAlign}
`;

const ShareLink = styled.div`
width: 100%;
align-items: center;
display: flex;
justify-content: flex-end;
`;

const MainBlock = () => {
    return (
        <FlexRowContainer>
            <figure style={{ height: '107px', width: '107px', margin: 0,  }}>
                <img src={LogoHubImg} alt="hub" height="100%" width="100%" style={{ borderRadius: '24px' }} />
            </figure>
            <Container marginRight="17.5px">
                <AppTitle width="146px">
                    smmhub: Insta
                    Marketing Tool
                </AppTitle>

                <AppSubtitle>
                    Reels, Story, Post Maker
                </AppSubtitle>

                <ButtonsContainer>
                    <Button
                        fontFamily="SF Pro Text Medium"
                        letterSpacing="0"
                    >Get</Button>
                    <InAppPurchases>
                        In-App Purchases
                    </InAppPurchases>
                    <ShareLink>
                        <figure style={{ height: '21px', width: '21px', margin: '0 3px' }}>
                            <img src={ShareAndArrowUpImg} alt="share" height="100%" width="100%" />
                        </figure>
                    </ShareLink>
                </ButtonsContainer>
            </Container>
        </FlexRowContainer>
    );
};

export default MainBlock;