import {SectionTitle} from "components/SectionTitle";
import React from "react";
import {FlexRowContainer} from "components/FlexRowContainer";
import {SectionLink} from "components/SectionLink";
import {SubTitle} from "components/SubTitle";
import Section from "components/Section";
import {useNavigate} from "react-router-dom";
import CollapsedContent from "components/CollapsedContent";


const WhatNew = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/version-history')
    };

    return (
        <Section paddingBottom="15px">
            <FlexRowContainer justifyContent="space-between" marginBottom="9.5px">
                <SectionTitle>What’s New</SectionTitle>
                <SectionLink onClick={handleClick}>Version History</SectionLink>
            </FlexRowContainer>
            <FlexRowContainer justifyContent="space-between" margin="0.5px 0 9px">
                <SubTitle>Version 3.0</SubTitle>
                <SubTitle>2w ago</SubTitle>
            </FlexRowContainer>

            <CollapsedContent
                visibleContent={
                    <React.Fragment key="last-new-version">
                        We are pleased to present the greatest brand-new update of smmhub. <br/>
                        Fully redesigned editor to get a better user experience. <br/>
                        - Create gorgeous content with a new set of tools for social networks <br/>
                        - Remove Photo Background in a second <br/>
                        - Use new trending templates <br/>
                        - Enjoy new stylish animations <br/>
                        - Add a lot of design stickers <br/>

                        Turn on push notifications to be in tune with next updates! Make sure to leave us a review
                        and let us know what you would like to see in the next update!
                    </React.Fragment>
                }
            />

        </Section>
    );
};


export default WhatNew;