import React, {useEffect, useState, lazy} from "react";
import MainBlock, {InAppPurchases} from "pages/MainPage/components/MainBlock";
import SectionsContainer from "components/SectionsContainer";
import Section from "components/Section";
import Developer from "pages/MainPage/components/Developer";
import WhatNew from "pages/MainPage/components/WhatNew";
import {Link} from "pages/MainPage/components/AppPrivacy";
import Subscriptions from "pages/MainPage/components/Subscriptions";
import CollapsedContent from "components/CollapsedContent";
import {SectionLink} from "components/SectionLink";
import {ReactComponent as HandImg} from "assets/icons/hand.svg";
import {FlexRowContainer} from "components/FlexRowContainer";
import ShortAppInfo from "pages/MainPage/components/ShortAppInfo";

import {SectionTitle} from "components/SectionTitle";
import {ScrollContainer} from "pages/MainPage/components/styled";
import AppItem from "components/AppItem";
import {useNavigate} from "react-router-dom";
import {AppList} from "components/AppList";
import {Button} from "pages/MainPage/components/GetButton";
import styled from 'styled-components';
import useWindowScrollPosition from "../../hook/useScrollHandler";
import LogoHubImg from "assets/icons/hub-icon.jpeg";
import {ReactComponent as ChevronLeftImg} from "assets/icons/chevron-left.svg";
import {IconContainer} from "components/BackHeader";
import PdfScannerImg from "assets/images/pdf-scanner.jpeg";
import PresetsImg from "assets/images/presets.jpeg";
import YouFastImg from "assets/images/you-fast.jpeg";

const ScreenPreviews = lazy(() => import("pages/MainPage/components/ScreenPreviews"));
const RatingAndReview = lazy(() => import("pages/MainPage/components/RatingAndReview"));
const AppPrivacy = lazy(() => import("pages/MainPage/components/AppPrivacy"));
const Information = lazy(() => import("pages/MainPage/components/Information"));


const InstallPanel = styled(FlexRowContainer)`
    height: 46px;
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 1;
    border-bottom: 1px solid rgb(196, 196, 196);
    padding: 0px 18px;
    transition: opacity, background 0.8s ease 0s;
`;


const MainPage = () => {
    const navigate = useNavigate();
    const scrollPosition = useWindowScrollPosition() || 0;

    const handleClick = () => navigate('/developer');

    const handleLoadContent = () => import('./components/descriptionContent').then(item => item.descriptionContent);

    const [screenPreviews, setScreenPreviews] = useState(null);
    const [ratingAndReview, setRatingAndReview] = useState(null);
    const [appPrivacy, setAppPrivacy] = useState(null);
    const [information, setInformation] = useState(null);

    useEffect(() => {
        setScreenPreviews(<ScreenPreviews/>);
        setRatingAndReview(<RatingAndReview/>);
        setAppPrivacy(<AppPrivacy/>);
        setInformation(<Information/>);
    }, []);

    return (
        <>
            <InstallPanel
                justifyContent="space-between"
                alignItems="center"
                style={{
                    opacity: scrollPosition > 18 ? 1 : 0,
                    ...(scrollPosition > 75 && {
                        background: 'rgb(242, 242, 242)'
                    }),
                }}>
                <FlexRowContainer
                    flex={1}
                    alignItems="center"

                    style={{
                        minWidth: '109px',
                        transition: 'opacity 0.8s ease 0s', opacity: scrollPosition > 84 ? 1 : 0
                    }}
                >
                    <IconContainer>
                        <ChevronLeftImg/>
                    </IconContainer>
                    <Link>Search</Link>
                </FlexRowContainer>

                <FlexRowContainer style={{width: '50%'}} justifyContent="center">
                    <figure style={{
                        height: `28px`,
                        width: `28px`,
                        margin: 0,
                        transition: 'opacity 0.8s ease 0s',
                        opacity: scrollPosition > 54 ? 1 : 0,
                    }}>
                        <img src={LogoHubImg} alt="hub" height="100%" width="100%" style={{borderRadius: '8px'}}/>
                    </figure>
                </FlexRowContainer>

                <FlexRowContainer
                    flex={1}
                    justifyContent="flex-end"
                    style={{
                        transition: 'opacity 0.8s ease 0s', opacity: scrollPosition > 84 ? 1 : 0
                    }}
                >
                    <InAppPurchases textAlign="end" paddingRight="5px">In-App Purchases</InAppPurchases>
                    <Button>
                        Get
                    </Button>
                </FlexRowContainer>
            </InstallPanel>

            <SectionsContainer style={{padding: '28px 0'}}>
                <Section>
                    <MainBlock/>
                    <ShortAppInfo/>
                </Section>

                <WhatNew/>

                <Subscriptions/>

                <Section>
                    {screenPreviews}
                    {/*<ScreenPreviews/>*/}
                </Section>
                <Section>
                    <CollapsedContent
                        visibleContent={
                            <p key="first-part-data">
                                Manage your Instagram in one place. Create engaging
                                stories and posts, plan your content, edit your photos
                                with a powerful editor, remove backgrounds and learn how to promote your profile<br/>
                                - ALL IN ONE APP.
                            </p>
                        }
                        loadContent={handleLoadContent}
                    />
                    <Developer/>
                </Section>

                {/*<RatingAndReview/>*/}
                {ratingAndReview}

                {/*<AppPrivacy/>*/}
                {appPrivacy}
                {/*<Information/>*/}
                {information}
                <div>
                    <FlexRowContainer justifyContent="space-between" marginTop="12px">
                        <SectionLink>Privacy Policy</SectionLink>
                        <HandImg/>
                    </FlexRowContainer>
                </div>


                <div>
                    <FlexRowContainer justifyContent="space-between" marginTop="42px">
                        <SectionTitle>More by UAB KAMELA</SectionTitle>
                        <SectionLink onClick={handleClick}>See All</SectionLink>
                    </FlexRowContainer>
                </div>

                <ScrollContainer marginTop={0}>
                    <AppList>
                        <AppItem
                            name="Presets for Lightroom - Vidl"
                            subtitle="LR Mobile Filters · Photo Editor"
                            appIcon={PresetsImg}
                        />
                        <AppItem
                            name="Tiny PDF Scanner for Documents"
                            subtitle="Cam Scanning, Sign & Fax Docs"
                            appIcon={PdfScannerImg}
                        />

                    </AppList>
                    <AppList>
                        <AppItem
                            name="YouFast - Intermittent Fasting"
                            subtitle="Fasting Diet and Water Tracker"
                            appIcon={YouFastImg}
                        />
                    </AppList>
                </ScrollContainer>
            </SectionsContainer>
        </>
    );
};

export default MainPage;