import React, {useEffect, useState} from "react";
import PageTitle from "components/PageTitle";
import SectionsContainer from "components/SectionsContainer";
import VersionContainer from "pages/VersionHistoryPage/components/VersionContainer";


const VersionHistoryPage = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        import('./components/versionsHistoryData')
            .then(item => {
                setData(item?.default || [])
            })
            .then(() => {
                window.scrollTo({top: 1, behavior: 'smooth'});
            })
    }, []);

    return (
        <div>
            <PageTitle>Version History</PageTitle>

            <SectionsContainer>
                {data.map(versionItem => (
                    <VersionContainer key={versionItem.version} {...versionItem} />
                ))}
            </SectionsContainer>
        </div>
    );
};

export default VersionHistoryPage;


