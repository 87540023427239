import styled from 'styled-components';
import { layout, flexbox, margin, padding, space } from 'styled-system';


export const FlexRowContainer = styled.section`
    display: flex;
    ${layout}
    ${flexbox}
    ${margin}
    ${padding}
    ${space}
`;