import React from "react";
import {Outlet} from "react-router-dom";
import styled from 'styled-components'
import BackHeader from "../components/BackHeader";


const StyledPageLayout = styled('div')`
padding: 42px 0;
`;

const PageLayout = () => {
    return (
        <>
            <BackHeader/>
            <StyledPageLayout>
                <Outlet/>
            </StyledPageLayout>
        </>
    )
};


export default PageLayout;