import React, {useEffect} from "react";
import {Link} from "pages/MainPage/components/AppPrivacy";
import {padding, margin, flexbox } from 'styled-system'
import GrayColorWrapper from "components/GrayColorWrapper";
import styled from 'styled-components'
import {Button} from "pages/MainPage/components/GetButton";
import {ReactComponent as FourPlusImg} from 'assets/icons/four-plus.svg';
import {useNavigate} from "react-router-dom";
import {GrayBlock, Plus} from "pages/MainPage/components/Subscriptions";
import SubImg from 'assets/images/subscription-1.jpeg'
import LogoHubImg from "assets/icons/hub-icon.jpeg";


const Container = styled.div`
    height: 100vh;
    
    background-color: #000;
    & > div {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: absolute;
        top: 53px;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 14px 18px;
        height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
    }
    ${padding}
`;

const Title = styled.div`
font-family: 'SF Pro Text';
font-weight: 600;
font-size: 15px;
line-height: 22px;
letter-spacing: -0.2px;

display: flex;
align-items: center;

position: relative;
justify-content: center;
${margin}
`;

const BodyContainer = styled.div`
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    align-items: center;
    z-index: 2;
    ${padding}
`;


const TitleBody = styled.div`
font-family: 'SF Pro Display';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;

letter-spacing: 0.24px;
${flexbox}
`;

const SubTitle = GrayColorWrapper(TitleBody);

const SmallGraySubtitle = styled.div`
color: #C4C4C5;
font-weight: 400;
font-size: 14px;
line-height: 18px;
letter-spacing: -0.42px;
`;

const SubscriptionOffer = () => {
    const navigate = useNavigate();

    const handleGet = () => {
    };

    const handleBack = () => navigate(-1);

    useEffect(()=> {
        window.scrollTo({ top: 1});
    }, []);

    return (
        <Container>
            <div>
                <Title>
                    Subscription Offer

                    <Link position="absolute" right="0" top="0" onClick={handleBack}>Done</Link>
                </Title>
                <BodyContainer paddingTop="90px">
                    <GrayBlock minWidth='102px' height='102px' padding="11px" margin="12px">
                        <Plus width="11.5px" height="11.5px"/>
                        <div style={{
                            height: '100%',
                            borderRadius: '5px',
                            borderTopRightRadius: '42px',
                            background: 'rgba(120, 120, 120, 0.6)',
                            backgroundImage: `url(${SubImg})`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        }}>
                            &nbsp;
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                bottom: '-12px',
                                left: '-12px',
                                height: '32px',
                                width: '32px',
                                background: '#ffffff',
                                borderRadius: '6px',
                                padding: '4px'
                            }}
                        >
                            <div
                                style={{
                                    height: '32px',
                                    width: '32px',
                                    background: '#D9D9D9',
                                    borderRadius: '6px',
                                    backgroundImage: `url(${LogoHubImg})`,
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                }}
                            >
                                &nbsp;
                            </div>
                        </div>
                    </GrayBlock>

                    <TitleBody display="flex" alignItems="center">
                        Stories Editor for Instagram <FourPlusImg/>
                    </TitleBody>
                    <SubTitle>
                        for smmhub: Insta Marketing Tool
                    </SubTitle>
                    <SmallGraySubtitle>
                        Reels, Story, Post Maker
                    </SmallGraySubtitle>
                    <Title margin="17.5px 0 40px">
                        Free for 3 days, then USD 4.99/week after trial.
                    </Title>
                    <Button
                        color="#3478f6"
                        background="#eeeeef"
                        padding="3.5px 19"
                        fontWeight="600"
                        fontSize="13px"
                        lineHeight="18px"
                        letterSpacing="-0.078px"
                        fontFamily="SF Pro Text"
                        onClick={handleGet}
                    >
                        Get
                    </Button>
                </BodyContainer>

            </div>
        </Container>
    )

};


export default SubscriptionOffer;