import styled from 'styled-components'
import { letterSpacing, margin, padding } from 'styled-system'

const PageTitle = styled.div`
  padding: 8.5px 0 8px;
  border-bottom: 1px solid #C6C6C8;
  font-family: 'SF Pro Display', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 31px;
  line-height: 37px;
  letter-spacing: 0.24px;
  color: #000000;
  ${letterSpacing}
  ${margin}
  ${padding}
`;

export default PageTitle;