


export const comments = [
    {
        header: 'Amazing app.',
        user: "Kolbas0ff",
        date: '01/16/2022',
        text: "All-in-one app to design your Stories! Love the various templates they have for making memorable stories to " +
                "capture your audience. All the necessary tools to create stylish stories, which will help businesses to " +
            "design their products. Templates, fonts and music options will allow you to run an IG page and create " +
            "professional content. Highly recommend",
    },

    {
        header: 'Easy customize',
        user: 'jaktusss',
        date: '1 wek ago',
        text: "The templates are not only stunning but very helpful as well. You can customize the templates to your " +
            "own variation with different fonts and text styles and animations.",
    },
    {
        header: 'Excellent',
        user: 'juike',
        date: '3w ago',
        text: "The app is excellent! I love the Stories  and Posts I can create for my company. There are so many template " +
            "designs and editing features. I was able to create a professionally looking story to Instagram immediately."
    }
];