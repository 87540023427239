import {ReactComponent as RatingStarsImg} from "assets/icons/rating-stars.svg";
import {ReactComponent as PhotoCameraImg} from "assets/icons/photo-camera.svg";
import {ReactComponent as DeveloperImg} from "assets/icons/developer.svg";
import React from "react";
import {ScrollContainer, ScrollItem} from "pages/MainPage/components/styled";

const ScrollItemContainer = ({header, body, footer}) => (
    <ScrollItem>
        <header>{header}</header>
        <main>{body}</main>
        <footer>{footer}</footer>
    </ScrollItem>
);

const ShortAppInfo = () => (
    <ScrollContainer borderTop={true} marginBottom="-5px">
        <div>
            <ScrollItemContainer
                header="25 ratings"
                body="4.7"
                footer={<RatingStarsImg/>}
            />
            <ScrollItemContainer
                header="age"
                body="4+"
                footer="Years Old"
            />
            <ScrollItemContainer
                header="category"
                body={<PhotoCameraImg/>}
                footer="Photo & Video"
            />
            <ScrollItemContainer
                header="developer"
                body={<DeveloperImg/>}
                footer="UAB KAMELA"
            />
            <ScrollItemContainer
                header="language"
                body="EN"
                footer="+6 More"
            />

            <ScrollItemContainer
                header="size"
                body="284.4"
                footer="MB"
            />
        </div>
    </ScrollContainer>
);


export default ShortAppInfo;