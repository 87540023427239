import React from "react";
import {BrowserRouter, Routes, Route,} from 'react-router-dom';
import './App.css';
import MainLayout from "./layouts/MainLayout";
import MainPage from "./pages/MainPage/MainPage";
import PageLayout from "./layouts/PageLayout";
import VersionHistoryPage from "./pages/VersionHistoryPage/VersionHistoryPage";
import AppPrivacyPage from "./pages/AppPrivacyPage/AppPrivacyPage";
import RatingsAndReviewsPage from "./pages/RatingsAndReviewsPage/RatingsAndReviewsPage";
import LearnMoreAboutAppPrivacyPage from "./pages/LearnMoreAboutAppPrivacyPage/LearnMoreAboutAppPrivacyPage";
import MoreByDeveloperPage from "pages/MoreByDeveloperPage/MoreByDeveloperPage";
import SubscriptionOffer from "components/SubscriptionOffer";


function App() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route element={<MainLayout/>}>
                        <Route index element={<MainPage/>}/>
                        <Route element={<PageLayout/>}>
                            <Route path="/version-history" element={<VersionHistoryPage/>}/>
                            <Route path="/developer" element={<MoreByDeveloperPage/>}/>
                            <Route path="/app-privacy" element={<AppPrivacyPage/>}/>
                            <Route path="/ratings-and-reviews" element={<RatingsAndReviewsPage/>}/>

                        </Route>

                    </Route>
                    {/*<Route element={<ModalLayout />}>*/}
                        <Route path="/learn-more-about-app-privacy" element={<LearnMoreAboutAppPrivacyPage/>}/>
                        <Route path="/subscription-offer" element={<SubscriptionOffer/>}/>
                    {/*</Route>*/}
                </Routes>

            </BrowserRouter>
        </>
    );
}

export default App;
