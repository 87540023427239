import React from "react";
import styled from 'styled-components';
import {flexbox, textAlign, margin, fontSize, fontFamily, letterSpacing, lineHeight} from 'styled-system'
import { ReactComponent as PurchasesImg } from 'assets/icons/purchases.svg'
import { ReactComponent as IdentifiersImg } from 'assets/icons/identifiers.svg'
import { ReactComponent as DiagnosticsImg } from 'assets/icons/diagnostics.svg'
import { ReactComponent as UserContentImg } from 'assets/icons/user-content.svg'
import { ReactComponent as UsageDataImg } from 'assets/icons/usage-data.svg'
import GrayColorWrapper from "components/GrayColorWrapper";

const CardContainer = styled.div`
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 18px;
    padding: 24px 20px;
    ${flexbox}
    ${textAlign}
    ${margin}
`;

export const Title = styled.div`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: -0.76px;
    margin-top: 5px;
    ${margin}
    ${fontFamily}
    ${letterSpacing}
    ${fontSize}
    ${lineHeight}
`;

export const SubTitle = styled(GrayColorWrapper('div'))`
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;
letter-spacing: -0.88px;
margin-top: 1px;
${fontSize}
${margin}
`;

const CardBody = styled.div`
    display: flex;
    padding-top: 3px;
    & > div {
        display: flex;
        :nth-last-child(-n+2) {
            padding-bottom: 4px;
        }
        &:nth-child(even) {
            & > svg {
                margin-left: 10.5px;
            }
        }
        width: 50%;
        text-align: start;
        align-items: center;
        padding: 5px 0px ;
        & > svg {
            margin-right: 10.5px;
        }
    };
    ${flexbox}
`;


const icons = {
    purchases: {
        icon: <PurchasesImg />,
        text: 'Purchases',
    },
    userContent: {
        icon: <UserContentImg />,
        text: 'User Content'
    },
    identifiers: {
        icon: <IdentifiersImg />,
        text: 'Identifiers'
    },
    usageData: {
        icon: <UsageDataImg />,
        text: 'Usage Data'
    },
    diagnostics: {
        icon: <DiagnosticsImg />,
        text: 'Diagnostics'
    }
};

const PrivacyCard = ({ icon = '', title = '', subtitle = '', bodyItems = [], ...props }) => {
    return (
        <CardContainer textAlign="center" margin="27px 0" { ...props}>
            <div>
                {icon}
            </div>
            <Title marginTop="-1px">{title}</Title>
            <SubTitle>{subtitle}</SubTitle>
            <CardBody flexWrap="wrap">
                {bodyItems.map(item => (
                    <div key={item}>
                        {icons[item].icon} {icons[item].text}
                    </div>
                ))}
            </CardBody>
        </CardContainer>
    );
};


export default PrivacyCard;