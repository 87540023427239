import {FlexRowContainer} from "components/FlexRowContainer";
import React from "react";
import styled from "styled-components";
import {ReactComponent as StarImg} from "assets/icons/star.svg";
import GrayColorWrapper from "components/GrayColorWrapper";
import {flexbox, fontSize, fontWeight, letterSpacing, lineHeight, margin, padding, textAlign} from "styled-system";

const GoldStarImg = styled(StarImg)`
width: 14.22px;
height: 14.22px;
    & > path {
        fill: #ED8834;
    }
`;

const Title = styled.div`
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    
    letter-spacing: 0.32px;
     ${fontSize}
     ${letterSpacing}
     ${fontWeight}
     ${lineHeight}
`;


const CommentWrapper = styled.div`
    flex-direction: column;
    flex-shrink: 0;
    max-width: calc(100% - 36px);
    background: #F2F2F6;
    padding: 16px 20px;
    border-radius: 6px;
    ${padding}
`;

const CommentText = styled.div`
    margin-bottom: 6px;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.4px;
    ${fontSize}
    ${lineHeight}
    ${margin}
    ${padding}
`;


const SubTitle = GrayColorWrapper(styled.div`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    ${margin}
    ${flexbox}
    ${textAlign}
    ${fontSize}
`);


const Comment = ({ textProps, headerText, commentText, header, date, user, ...otherProps }) =>
    <CommentWrapper {...otherProps}>
        <FlexRowContainer justifyContent="space-between" paddingBottom="7px" flexDirection="column">
            <FlexRowContainer justifyContent="space-between" alignItems="center">
                <Title
                    fontWeight='600'
                    fontSize='17px'
                    lineHeight='22px'
                    letterSpacing='-0.408px'
                    {...headerText?.title}
                >
                    {header || 'Amazing app.'}
                </Title>
                <SubTitle fontSize={headerText?.subTitle.fontSize || '17px'}>
                    {date}
                </SubTitle>

            </FlexRowContainer>

            <FlexRowContainer justifyContent="space-between" alignItems="center" marginTop="2.5px">
                <FlexRowContainer>
                    <GoldStarImg/>
                    <GoldStarImg/>
                    <GoldStarImg/>
                    <GoldStarImg/>
                    <GoldStarImg/>
                </FlexRowContainer>
                <SubTitle fontSize={headerText?.subTitle.fontSize || '17px'}>
                    {user}
                </SubTitle>
            </FlexRowContainer>
        </FlexRowContainer>
        <CommentText {...textProps}>
            {commentText}
        </CommentText>
    </CommentWrapper>
;


export default Comment