import styled from 'styled-components';
import { fontSize, fontWeight, lineHeight, letterSpacing } from 'styled-system';


const GrayColorWrapper = Component => styled(Component)`
    color: #8A8A8D;
    
    ${fontSize}
    ${fontWeight}
    ${lineHeight}
    ${letterSpacing}
`;

export default  GrayColorWrapper;