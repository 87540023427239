import React, {useEffect} from "react";
import styled from 'styled-components';
import {fontSize, letterSpacing, fontWeight, lineHeight, padding} from "styled-system";
import {FlexRowContainer} from "components/FlexRowContainer";

import {ReactComponent as ChevronLeftImg} from 'assets/icons/chevron-left.svg';
import {Link} from "pages/MainPage/components/AppPrivacy";
import Comment from "components/Comment";
import Rate from "components/Rate";
import {ScrollContainer} from "pages/MainPage/components/styled";
import { comments } from 'components/comments'


const Title = styled.div`
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
    
    letter-spacing: 0.32px;
     ${fontSize}
     ${letterSpacing}
     ${fontWeight}
     ${lineHeight}
     ${padding}
`;


const RatingsAndReviewsPage = () => {
    useEffect(() => {
        window.scrollTo({top: 1, behavior: 'smooth'});
    }, []);

    return (
        <div>
            <Title paddingTop="7px">Ratings & Reviews</Title>

            <Rate margin="25px 0 36px"/>

            <FlexRowContainer justifyContent="flex-end" margin="16px 0 11px" >
                <Link
                    fontSize="20px"
                    lineHeight="24px"
                    letterSpacing="0.2px"
                >
                    Sort by Most Helpful
                </Link>
                <ChevronLeftImg
                    style={{
                        transform: 'rotate(-90deg)',
                        height: '16px',
                        margin: 'auto 5px auto 9px'
                    }}
                />
            </FlexRowContainer>

            <ScrollContainer borderTop={true} marginBottom="-5px">
                {comments.map(({ text, header, date, user }) => (
                    <Comment
                        key={text}
                        textProps={{ marginTop: '4px' }}
                        commentText={text}
                        header={header}
                        date={date}
                        user={user}
                    />
                ))}
            </ScrollContainer>
        </div>
    );
};
export default RatingsAndReviewsPage;