import {ReactComponent as RatingImg} from "assets/icons/rating.svg";
import BodyText from "components/BodyText";
import {ReactComponent as StarImg} from "assets/icons/star.svg";
import {FlexRowContainer} from "components/FlexRowContainer";
import React from "react";
import styled from "styled-components";
import GrayColorWrapper from "components/GrayColorWrapper";
import {flexbox, fontSize, margin, textAlign} from "styled-system";


const StarWrapper = styled.div`
display: flex;
flex: 0 0 40px;
padding: 0 10px 0 30px;
justify-content: flex-end;
`;


const SubTitle = GrayColorWrapper(styled.div`
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    ${margin}
    ${flexbox}
    ${textAlign}
    ${fontSize}
`);


const ProgressStyled = ({value = 0}) => (
    <div style={{backgroundColor: '#EFEFEF', borderRadius: '2px', width: '100%', height: '4px'}}>
        <div style={{
            height: "inherit",
            width: `${value}%`,
            backgroundColor: '#B2B2B5',
            borderTopLeftRadius: 'inherit',
            borderBottomLeftRadius: 'inherit'
        }}/>
    </div>
);

const Rate = (props) =>
    <FlexRowContainer {...props}>
        <div>
            <RatingImg/>
            <BodyText fontWeight="600" fontSize="15px" lineHeight="20px" textAlign="center">
                out of 5
            </BodyText>
        </div>
        <div
            style={{
                flex: 1,
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            <div style={{display: 'flex', alignItems: 'center'}}>
                <StarWrapper>
                    <StarImg/><StarImg/><StarImg/><StarImg/><StarImg/>
                </StarWrapper>
                <ProgressStyled value={92}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <StarWrapper>
                    <StarImg/><StarImg/><StarImg/><StarImg/>
                </StarWrapper>
                <ProgressStyled/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <StarWrapper>
                    <StarImg/><StarImg/><StarImg/>
                </StarWrapper>
                <ProgressStyled/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <StarWrapper>
                    <StarImg/><StarImg/>
                </StarWrapper>
                <ProgressStyled/>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <StarWrapper>
                    <StarImg/>
                </StarWrapper>
                <ProgressStyled value={7}/>
            </div>

            <SubTitle marginTop="9px" textAlign="end">25 Ratings</SubTitle>
        </div>
    </FlexRowContainer>
;

export default Rate;