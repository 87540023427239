import styled from 'styled-components';
import { layout, flexbox, margin, padding } from 'styled-system';


export const SectionLink = styled.div`
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    
    letter-spacing: -0.24px;
    
    color: #3478F6;
    
    display: flex;
    align-items: center;

    ${layout}
    ${flexbox}
    ${margin}
    ${padding}
    ${padding}
`;