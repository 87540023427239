import {FlexRowContainer} from "components/FlexRowContainer";
import React from "react";
import {Container} from "components/Container";
import {Button} from "pages/MainPage/components/GetButton";
import BodyText from "components/BodyText";
import {ButtonsContainer} from "components/ButtonsContainer";
import styled from 'styled-components';
import {margin} from 'styled-system';


const Title = styled.div`
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 18px;

letter-spacing: -0.22px;
${margin}
`;

const HrLine = styled.hr`
    position: absolute;
    transform: rotate(0deg);
    bottom: -9px;
    right: 0;
    width: 100%;
    margin: 0;
    width: calc(100% - 13.5px);
    // border-top: 1px solid #C6C6C8;
    background-color: #C6C6C8;
    
    height: 1px;
    border: 0;
`;


const AppItem = ({appIcon, name, subtitle, showHr = false}) => {
    return (
        <FlexRowContainer>
            <figure style={{
                height: '56px',
                width: '56px',
                margin: 0,
            }}>
                <img
                    src={appIcon}
                    loading="lazy"
                    alt={name}
                    height="56px"
                    width="56px"
                    style={{
                        background: 'rgba(217, 217, 217, 0.6)',
                        borderRadius: '12px',
                        maxHeight: '56px',
                        maxWidth: '56px',
                        overflow: 'hidden'
                    }}/>
            </figure>
            <div style={{width: '100%', position: 'relative', alignItems: 'center', display: 'flex'}}>
                <FlexRowContainer flex={1}>
                    <Container marginRight="17.5px">
                        <Title marginTop="auto">{name}</Title>
                        <BodyText marginBottom="auto" className="truncate-line-clamp-1" >
                            {subtitle}
                        </BodyText>
                    </Container>
                    <Container flex="0 1 65px" margin={0}>
                        <ButtonsContainer flexDirection="column" alignItems="center" margin="auto 0">
                            <Button
                                color="#3478f6"
                                background="#eeeeef"
                                padding="5px 9.5px 4.5px"
                                fontWeight="600"
                                fontSize="12px"
                                lineHeight="16px"
                                letterSpacing="0.078px"
                                fontFamily="SF Pro Text Medium"
                                width="-webkit-fill-available"
                                justifyContent="center"
                            >
                                Get
                            </Button>
                            <BodyText
                                marginTop="5px"
                                textAlign="center"
                                fontSize="8px"
                                lineHeight="9px"
                            >
                                In-App Purchases
                            </BodyText>
                        </ButtonsContainer>
                    </Container>
                </FlexRowContainer>
                {showHr && <HrLine/>}
            </div>
        </FlexRowContainer>
    );
};


export default AppItem;