import styled from 'styled-components';
import { layout, flexbox, margin, padding } from 'styled-system';


export const SectionTitle = styled.div`
font-family: 'SF Pro Display';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.44px;
    ${layout}
    ${flexbox}
    ${margin}
    ${padding}
    ${padding}
`;