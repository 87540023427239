import styled from 'styled-components'
import { margin, padding } from 'styled-system';


const Section = styled.section`
    padding: 10px 0px 16px;
    font-size: 14px;
    line-height: 18px;
    font-style: normal;
    position: relative;
    ${margin}
    ${padding}
`;

export default Section;