import React, {useEffect} from "react";
import PageTitle from "components/PageTitle";
import SectionsContainer from "components/SectionsContainer";
import {SectionTitle} from "components/SectionTitle";
import AppItem from "components/AppItem";
import {ScrollContainer} from "pages/MainPage/components/styled";
import {AppList} from "components/AppList";
import YouFastImg from 'assets/images/you-fast.jpeg';
import PdfScannerImg from 'assets/images/pdf-scanner.jpeg';
import PresetsImg from 'assets/images/presets.jpeg';
import HubImg from 'assets/icons/hub-icon.jpeg';


const MoreByDeveloperPage = () => {
    useEffect(() => {
        window.scrollTo({top: 1});
    }, []);

    return (
        <div>
            <PageTitle letterSpacing="0.16px" padding="0" paddingBottom="22px">UAB KAMELA</PageTitle>

            <SectionsContainer>
                <SectionTitle padding="10px 0">Latest Release</SectionTitle>

                <div style={{marginBottom: '40px'}}>
                    <AppItem
                        name="YouFast - Intermittent Fasting"
                        subtitle="Fasting Diet and Water Tracker"
                        appIcon={YouFastImg}
                    />
                </div>

                <SectionTitle>Apps</SectionTitle>
                <ScrollContainer marginTop={0}>
                    <AppList>
                        <AppItem
                            showHr
                            name="YouFast - Intermittent Fasting"
                            subtitle="Fasting Diet and Water Tracker"
                            appIcon={YouFastImg}
                        />
                        <AppItem
                            showHr
                            name="smmhub: Insta Marketing Tool"
                            subtitle="Reels, Story, Post Maker"
                            appIcon={HubImg}
                        />
                        <AppItem
                            name="Presets for Lightroom - Vidl"
                            subtitle="LR Mobile Filters · Photo Editor"
                            appIcon={PresetsImg}
                        />
                    </AppList>
                    <AppList>
                        <AppItem
                            name="Tiny PDF Scanner for Documents"
                            subtitle="Cam Scanning, Sign & Fax Docs"
                            appIcon={PdfScannerImg}
                        />

                    </AppList>
                </ScrollContainer>


            </SectionsContainer>
        </div>
    );
};

export default MoreByDeveloperPage;


