import React from "react";
import styled from 'styled-components'
import Section from "components/Section";
import MoreButton from "components/MoreButton";
import GrayColorWrapper from "components/GrayColorWrapper";

const VersionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    letter-spacing: -0.44px;
    margin-bottom: 5px;
`;

const Version = styled.div`
    font-weight: 500;
`;
const Date = styled(GrayColorWrapper('div'))`
    font-weight: 400;
`;

const Desc = styled.div`
    letter-spacing: -0.42px;
    &>div:last-child {
        width: calc(100% - 32px)
    }
`;

const VersionContainer = ({ version, date, desc }) => {
    return (
        <Section>
            <VersionHeader>
                <Version>{version}</Version>
                <Date>{date}</Date>
            </VersionHeader>
            <Desc>{desc}</Desc>
            <MoreButton />
        </Section>
    )
};

export default VersionContainer