import React from "react";
import styled from "styled-components";
import { bottom } from 'styled-system'


const Container = styled.span`
    position: absolute;
    right: 0;
    bottom: 16px;
    float: left;
    color: #3478F6;
    letter-spacing: -0.44px;
    font-size: 14px;
    line-height: 18px;
    background-color: #fff;
    padding-left: 4px;
    filter: drop-shadow(-11px 0px 7px #ffffff);
    ${bottom}
`;

const MoreButton = (props) => <Container {...props}>more</Container>;

export default MoreButton;