import styled from "styled-components";

export const AppList = styled.div`
    flex-direction: column;
    flex: 0 0 calc(100% - 18px);
    margin-bottom: 40px;
    margin-right: 9px;
    min-width: calc(100% - 48px - 18px);
    & > section:not(:last-child) {
        margin-bottom: 15px;
    }
`;
