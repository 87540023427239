import React from "react";
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {ReactComponent as ChevronLeftImg} from './../assets/icons/chevron-left.svg';

const StyledBackContainer = styled.div`
    display: flex;
    color: #007AFF;
    align-items: center;
    padding: 9px 8px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    backdrop-filter: blur(10px);
    z-index: 2;
    
    & > span {
    font-family: 'SF Pro Text';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    
    letter-spacing: -0.408px;
    }
`;

export const IconContainer = styled.span`
    display: flex;
    align-items: center;
    height: 24px;
    width: 18px;
    padding: 1px;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
`;

const BackHeader = () => {
    const navigate = useNavigate();
    return (
        <StyledBackContainer onClick={() => navigate(-1)}>
            <IconContainer>
                <ChevronLeftImg/>
            </IconContainer>
            <span>
                Back
            </span>
        </StyledBackContainer>
    );
};


export default BackHeader;