import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import styled from 'styled-components'

import LearAppPrivacyImg from "assets/images/Learn-App-Privacy.svg";
import {ReactComponent as CloseImg} from "assets/icons/Close.svg";
import BodyText from "components/BodyText";


const Title = styled.div`
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    letter-spacing: 0.12px;
`;

const LearnMoreAboutAppPrivacyPage = () => {
    const navigate = useNavigate();


    const [text, setText] = useState(null);
    useEffect(() => {
        import('./learnAppPrivacyText')
            .then(item => setText(item.learnAppPrivacyText))
            .catch(console.log)
    }, []);

    useEffect(()=> {
        window.scrollTo({ top: 1});
    }, []);

    return (
        <>
            <div style={{padding: '38px 18px 66px', position: 'relative', background: '#F6F6F9', display: 'grid'}}>
                <CloseImg
                    onClick={() => navigate(-1)}
                    style={{
                        position: 'fixed',
                        right: '18px',
                        top: '18px',
                    }}
                />
                <BodyText fontSize="14px" paddingBottom="4px">
                    DEEP DIVE
                </BodyText>
                <Title>
                    Learn more about<br/>
                    App Privacy
                </Title>
                <figure
                    style={{
                        margin: 0,
                        width: '356px',
                        height: '254px',
                        float: 'right',
                        paddingTop: '55px',
                        marginRight: '-18px',
                        marginLeft: 'auto'
                    }}
                >
                    <img src={LearAppPrivacyImg} alt="lean app privacy"/>
                </figure>
            </div>
            <div style={{ padding: '0px 18px 40px', background: '#fff' }}>
                {text}
            </div>
        </>
    );
};


export default LearnMoreAboutAppPrivacyPage;