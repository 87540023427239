import styled from 'styled-components';
import { color, background, padding, fontWeight, lineHeight, fontSize, letterSpacing, fontFamily, width, justifyContent } from 'styled-system';

export const Button = styled.div`
    font-family: 'SF Pro Text';
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.078em;
    text-transform: uppercase;
    font-weight: 600;
    
    display: flex;
    align-items: center;
    width: max-content;
    padding: 3.5px 19px;
    border-radius: 13px;
    
    background: #3478F6;
    color: #FFFFFF;
    ${color}
    ${background}
    ${padding}
    
    ${fontWeight}
    ${lineHeight}
    ${fontSize}
    ${letterSpacing}
    ${fontFamily}
    ${fontFamily}
    ${width}
    ${justifyContent}
`;