import styled from 'styled-components'
import React from "react";
import {Outlet} from "react-router-dom";


const StyledMainLayout = styled('div')`
    padding: 0 18px;
`;

const MainLayout = () => {

    return (
        <StyledMainLayout>
            <Outlet />
        </StyledMainLayout>
    )
};

export default MainLayout;