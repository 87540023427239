import ChevronRightImg from "assets/icons/chevron.svg";
import React from "react";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const DeveloperContainer = styled.div`
margin: 5.5px 0 18.5px;
display: flex;
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 400;
& .company-name {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.38px;
    color: #3478F6;
}
& .company-type {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: #8A8A8D;
}
`;

const Developer = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/developer')
    };

    return (
        <DeveloperContainer onClick={handleClick}>
            <div style={{width: '100%'}}>
                <div className="company-name">UAB KAMELA</div>
                <div className="company-type">Developer</div>
            </div>
            <figure style={{flex: 1, height: '15px', margin: 'auto 0'}}>
                <img src={ChevronRightImg} alt="chevron"/>
            </figure>
        </DeveloperContainer>
    );
};


export default Developer;