import styled from "styled-components";
import GrayColorWrapper from "components/GrayColorWrapper";


export const AppSubtitle = styled(GrayColorWrapper('div'))`
font-family: 'SF Pro Text';
font-weight: 400;
font-size: 13px;
line-height: 18px;
letter-spacing: 0.08px;
padding-left: 1px;
margin-top: 2px;
`;