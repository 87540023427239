import styled from 'styled-components';
import { fontSize, fontWeight, lineHeight, letterSpacing, position, margin, padding } from 'styled-system';


const LinkContainer = Component => styled(Component)`
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px;

    color: #3478F6;
    
    ${fontSize}
    ${fontWeight}
    ${lineHeight}
    ${letterSpacing}
    ${position}
    ${margin}
    ${padding}
`;

export default  LinkContainer;