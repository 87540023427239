import React, {useState} from "react";
import MoreButton from "components/MoreButton";

const fakePromise = () => new Promise(resolve => {
    setTimeout(() => {
        console.log('fakePromise setTimeout');
        resolve(null);
    }, 1);
});

const CollapsedContent = ({visibleContent, loadContent = fakePromise}) => {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState([
        visibleContent
    ]);

    const handleShowMore = () => {
        
        loadContent()
            .then(item => {
                console.log('handleShowMore then');
                setContent(prev => [...prev, item]);
            })
            .catch(e => {
                console.log(e)
            })
            .finally(() => {
                setOpen(!open);
            });
    };

    return (
        <div style={{
            position: 'relative',
            marginBottom: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            maxHeight: '100%',
            ...(!open && {
                WebkitLineClamp: 3,
            })
        }}
        >
            {content}

            {!open && <MoreButton bottom="0" onClick={handleShowMore}/>}
        </div>
    )
};


export default CollapsedContent;