import styled from "styled-components";
import { width, fontFamily, letterSpacing,fontWeight } from 'styled-system';


export const AppTitle = styled.div`
font-family: 'SF Pro Display';
font-weight: 600;
font-size: 20px;
line-height: 24px;
letter-spacing: 0.38px;

${letterSpacing}
${fontFamily}
${fontWeight}
${width}
`;