import styled from "styled-components";
import {FlexRowContainer} from "components/FlexRowContainer";
import GrayColorWrapper from "components/GrayColorWrapper";
import { margin } from 'styled-system'

export const ScrollContainer = styled(FlexRowContainer)`
    padding: 0 11px 11.5px;
    margin: 18px -18px;
    ${margin}
    scroll-snap-type: x mandatory;
    
    & > div {
        display: flex;
        padding-top: 8px;
        margin: 0px 7px;
        ${({ borderTop }) => borderTop === true ? 'border-top: 1px solid #D9D9D9;' : ''}
        width: max-content;
        
        & > div:last-child {
             margin-right: -18px;
             padding-right: 18px;
        }
        & > div:not(:last-child) > main {
            border-right: 1px solid #D9D9D9;
        }
        & > figure {
            position: relative;
            margin: 0;
            &:not(:last-child) {
                margin-right: 8.89px;
            }
            &:last-child {
                margin-right: -18px;
                padding-right: 18px;
            }
            & img {
                border-radius: 23px;
                width: 220px;
                height: 475px;
            }
            width: 220px;
            height: 475px;
        }
    }
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-margin: 20px;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    
    
`;


export  const BackdropContainer = styled.div`
    border-radius: 23px;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    backdrop-filter: blur(5px);
`;

export  const ScrollItem = styled(GrayColorWrapper('div'))`
    font-family: 'SF Pro Text';
    min-width: 94.5px;
    height: 53px;
    display: flex;
    flex-direction: column;
    align-items: center;
    & header {
        font-weight: 600;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.06px;
        text-transform: uppercase;
    }
    & main {
        font-family: 'SF Pro Display';
        letter-spacing: 0.38px;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        padding: 7px 0 4px;
        min-height: 24px;
        width: 100%;
        text-align: center;
    }
    & footer {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.18px;
        min-height: 16px;
    }
`;