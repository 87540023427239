import React, {useEffect} from "react";
import PageTitle from "components/PageTitle";
import SectionsContainer from "components/SectionsContainer";
import Section from "components/Section";
import { Title, SubTitle } from "components/PrivacyCard";
import {Link} from "pages/MainPage/components/AppPrivacy";
import {ReactComponent as DataUsedToTrackYouImg} from "assets/icons/data-used-to-track-you.svg";
import {ReactComponent as DataNotLinkedToYouImg} from "assets/icons/data-not-linked-to-you.svg";
import styled from "styled-components";
import {margin} from "styled-system";

import { ReactComponent as PurchasesImg } from 'assets/icons/purchases.svg'
import { ReactComponent as IdentifiersImg } from 'assets/icons/identifiers.svg'
import { ReactComponent as DiagnosticsImg } from 'assets/icons/diagnostics.svg'
import { ReactComponent as UserContentImg } from 'assets/icons/user-content.svg'
import { ReactComponent as UsageDataImg } from 'assets/icons/usage-data.svg'
import {FlexRowContainer} from "components/FlexRowContainer";
import BodyText from "components/BodyText";
import LearnMore from "components/LearnMore";


const SectionTitle = styled.div`
font-family: 'SF Pro Text';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 18px;

letter-spacing: -0.18px;
margin-bottom: 17px;
${margin}
`;

const AppPrivacyPage = () => {
    useEffect(()=> {
        window.scrollTo({ top: 1});
    }, []);

    return (
        <div>
            <PageTitle letterSpacing="0.16px" padding="0" paddingBottom="8px">App Privacy</PageTitle>

            <SectionsContainer >
                <Section paddingTop="14px">
                    The developer, <b>UAB KAMELA</b>, indicated that the app's
                    privacy practices may include handling of data as
                    described below. This information has not been
                    verified by Apple. For more information, see the&nbsp;
                    <Link>developer's privacy policy.</Link>
                </Section>
                <Section padding="0">
                    <p>
                        To help you better understand the developer's responses, see &nbsp;
                        <Link>Privacy Definitions and Examples</Link>.
                    </p>
                    <p>
                        Privacy practices may vary based, for example, on the features you use or your age. &nbsp;
                        <LearnMore />
                    </p>
                </Section>
                <Section>
                    <div><DataUsedToTrackYouImg style={{ margin: '10px 5px -2px'}} /></div>
                    <Title
                        fontFamily="SF Pro Display"
                        letterSpacing="0.52px"
                        fontSize="20px"
                        lineHeight="24px"
                    >
                        Data Used to Track You
                    </Title>
                    <SubTitle fontSize="14px" marginBottom="7px">
                        The following data may be used to track you across apps and websites owned by other companies:
                    </SubTitle>

                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <PurchasesImg style={{ width: '27px' }} />
                            <div>Purchases</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Purchase History
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <IdentifiersImg style={{ width: '27px' }} />
                            <div>Identifiers</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                User ID<br/>
                                Device ID
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                </Section>

                <Section>
                    <div><DataNotLinkedToYouImg style={{ margin: '15px 3px -1px'}} /></div>
                    <Title
                        fontFamily="SF Pro Display"
                        letterSpacing="0.52px"
                        fontSize="20px"
                        lineHeight="24px"
                    >
                        Data Not Linked to You
                    </Title>
                    <SubTitle fontSize="14px">
                        The following data, which may be collected but is not
                        linked to your identity, may be used for the following
                        purposes:
                    </SubTitle>
                </Section>

                <Section>
                    <SectionTitle>Developer’s Advertising or Marketing</SectionTitle>

                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <PurchasesImg style={{ width: '27px' }} />
                            <div>Purchases</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Purchase History
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <IdentifiersImg style={{ width: '27px' }} />
                            <div>Identifiers</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                User ID<br/>
                                Device ID
                            </BodyText>
                        </FlexRowContainer>
                    </div>

                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <UsageDataImg style={{ width: '27px' }} />
                            <div>Usage Data</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Advertising Data
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                </Section>



                <Section>
                    <SectionTitle>Analytics</SectionTitle>

                    <div style={{ padding: '7px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <PurchasesImg style={{ width: '27px' }} />
                            <div>Purchases</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Purchase History
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <IdentifiersImg style={{ width: '27px' }} />
                            <div>Identifiers</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                User ID<br/>
                                Device ID
                            </BodyText>
                        </FlexRowContainer>
                    </div>

                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <UsageDataImg style={{ width: '27px' }} />
                            <div>Usage Data</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Product Interaction<br/>
                                Advertising Data
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <DiagnosticsImg style={{ width: '27px' }} />
                            <div>Diagnostics</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Crash Data
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                </Section>


                <Section>
                    <SectionTitle>App Functionality</SectionTitle>

                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <PurchasesImg style={{ width: '27px' }} />
                            <div>Purchases</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Purchase History
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <UserContentImg style={{ width: '27px' }} />
                            <div>User Content</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Customer Support
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <IdentifiersImg style={{ width: '27px' }} />
                            <div>Identifiers</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                User ID<br/>
                                Device ID
                            </BodyText>
                        </FlexRowContainer>
                    </div>

                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <UsageDataImg style={{ width: '27px' }} />
                            <div>Usage Data</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Product Interaction<br/>
                                Advertising Data
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                    <div style={{ padding: '5px 0'}}>
                        <FlexRowContainer alignItems="center" marginBottom="5.5px">
                            <DiagnosticsImg style={{ width: '27px' }} />
                            <div>Diagnostics</div>
                        </FlexRowContainer>
                        <FlexRowContainer>
                            <BodyText marginLeft="29px">
                                Crash Data
                            </BodyText>
                        </FlexRowContainer>
                    </div>
                </Section>

            </SectionsContainer>
        </div>
    )
};


export default AppPrivacyPage;